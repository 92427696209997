
@font-face {
  font-family: 'GreycliffCF';
  src: local('greycliff-cf'), url(./assets/fonts/greycliff-cf-regular.woff) format('woff');
}

body {
  margin: 0;
  font-family: 'GreycliffCF', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

.nav-dot {
  width: 12px;
  height: 12px;
  border: 1px solid white;
  border-radius: 50%;
  margin: 4px 6px;
  cursor: pointer;
}

.nav-dot-filled {
  background-color: white;
}

.sidebar-drawer {
  display: none;
}

@media screen and (max-width: 1024px) {
  .sidebar-drawer {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: calc(100% - 110px);
    top: 0;
    position: absolute;
    transform: translateX(400px);
    transition: transform 0.5s ease-in-out;
    background-color: white;
    z-index: 5;
    padding: 25px;
    display: flex;
    flex-direction: column;
  }

  .sidebar-drawer.show {
    transform: translateX(100px);
    transition: transform 0.5s ease-in-out;
  }

  .sidebar-contact {
    text-decoration: underline;
    text-align: center;
    color: black;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 2.55px;
    cursor: pointer;
    margin-top: 25px;
  }

  .sidebar-phrase {
    text-decoration: underline;
    text-align: center;
    color: black;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 2.55px;
    cursor: pointer;

  }
}
body, * {
  box-sizing: border-box;
}

a, a:visited {
  color: white;
}

.section-slide {
  background-color: black;
  height: 100vh;
  padding: 69px;
}


.hanging-quotes::before{
  content: "\201C";
  position: relative;
  left:-.2%;
  margin-left: -3%;

}

.hanging-quotes::after{
  content: "\201D";
}

.carousel-dots {
  position: absolute;
  right: 15px;
  top: 0px;
  bottom: 0px;
  z-index: 4;
  height: fit-content;
  margin: auto 0px;
}

.section-slide {

  z-index: 2;
  position: absolute;
  background: none;
  border: none;
  padding: 72px;
  margin: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  /* top: 112px; */
  /* transition: opacity .4s ease-in; */
  /* transition: transform 0.4s ease, opacity 0.4s ease; */
}

.section-slide-scroll-indicator {
  height: 45px;
  width: fit-content;
  color: white;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0px auto;
  z-index: 6;
  -webkit-animation: bounce 3s infinite ease-in-out;
    -o-animation: bounce 3s infinite ease-in-out;
    -ms-animation: bounce 3s infinite ease-in-out;
    -moz-animation: bounce 3s infinite ease-in-out;
    animation: bounce 3s infinite ease-in-out;
}


.section-slide.visible {
  opacity: 1;
  background: black;
  background-color: black;
  height: 100vh;
}


.section-slide-content-container {
  display: flex;
  height: 95%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.section-slide-stat-container {
  width: 45%;
  text-align: left;
}

.section-slide-video {
  width: 55%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-slide-bottom-content {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.section-slide-share-container {
  display: flex;
  justify-content: flex-end;
}

.share-icons-drawer {
  position: relative;
  opacity: 0;
  transition: all .6s ease-in;
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 0px;
  pointer-events: none;
  transform: translateX(50px);
}

.share-icons-drawer.open {
  display: flex;
  width: 360px;
  transform: translateX(50px);
  opacity: 1;
  pointer-events: all;
  /* transition: all .7s ease-in; */
  background-color: white;
}

.share-button {
  color: white;
  opacity: 0;
  transition: all 1s ease-in;
  font-weight: 600;
  cursor: pointer;

}

.share-button.open {
  display: flex;
  opacity: 1;
  /* transition: all 1s ease-in; */
  /* margin-left: 75px; */
}

.social-share-link {
  width: 90px;
  height: 50px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.outer-container .carousel-wrapper p,
/* .section-slide {
  font-weight: 700;
  font-size: 20px;
  color: var(--white);
  line-height: 1.3;
} */

.section-slide.visible .fade-animation, .section-slide.visible .fade-slide-animation{
  opacity: 1;
}

.fade-animation {
  opacity: 0;
  animation-name: fade;
  animation-duration: 2s;
}

.fade-slide-animation {
  opacity: 0;
  animation-name: fadeAndSlide;
  animation-duration: 2s;
}

@-webkit-keyframes bounce {
    0% { transform: translateY(-2px)  }
    50% { transform: translateY(5px) }
    100% { transform: translateY(-2px) }
}

@keyframes bounce {
    0% { transform: translateY(-2px)  }
    50% { transform: translateY(5px) }
    100% { transform: translateY(-2px) }
}

@keyframes fade {
    from {
        opacity: 0;
        /* transform: translateX(0); */
        /* scale(.9, .9); */
    }
    to {
        opacity: 1;
        /* transform: translateX(50px); */
        /* transform: scale(1, 1); */
    }
}

@keyframes fadeAndSlide {
    from {
        opacity: 0;
        transform: translateY(50px);
        /* scale(.9, .9); */
    }
    to {
        opacity: 1;
        transform: translateY(0px);
        /* transform: scale(1, 1); */
    }
}

.carousel {
  height: 100%;
  display: flex;
  flex: 5;
  align-items: center;
  color: var(--white);

}

.slides {
  align-self: flex-start;
  flex: 1;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.carousel-inner {
  position: relative;
  max-height: 100vh;
  height: 100vh;
}

.finePrintMobile {
  display: none;
}


@media screen and (max-width: 1300px) {
  .section-slide-video {
    height: 60%;
  }
}


@media screen and (max-width: 1024px) { /* TABLET 768-1024*/
  .finePrintMobile {
    display: flex;
    margin-top: 15px;
    text-align: center;
    justify-content: center;
  }

  .finePrintDesktop {
    visibility: hidden;
    /* display: none; */
  }

  .section-slide {
    padding: 22px;
  }

  .section-slide-content-container {
    flex-direction: column;
    align-items: space-between;
    justify-content: center;
    width: 100%;
  }

.intro-content-container {
  align-items: flex-start;
}
  /* .section-slide-bottom-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  } */
  .section-slide-stat-container {
    text-align: center;
    width: 95%;
  }

  .section-slide-video {
    width: 100%;
    height: 60%;
    margin-top: 12px;
  }
  .section-slide {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }
  .section-slide.visible {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }
  .carousel-inner {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }
  .slides {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }

  .section-slide-share-container {
    margin-top: 8px;
  }


  .social-share-link {
    height: 60px;
  }

  .share-icons-drawer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
  }

  .share-icons-drawer.open {
    transform: translateX(0px);
    /* position: absolute;
    left: 0;
    right: 0;
    bottom: 0; */
    width: 100%;

  }
  .carousel-dots {
    display: none;
  }

.indicator-under-share-drawer {
  display: none;
}


}


@media screen and (max-width: 768px) { /* mobile < 768*/
  .section-slide {
    padding-top: 60px;
  }

  .section-slide-scroll-indicator {
    height: 36px;
    bottom: 15px;
  }

  .carousel-dots {
    display: none;
  }

  .scroll-indicator-icon {
    transform: scale(.7, .7);
  }

  .hanging-quotes::before{
    content: "\201C";
    position: relative;
    left:-.1%;
    margin-left: -1%;

  }

}

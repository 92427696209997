.navbar {
  position: fixed;
  z-index: 3;
  /* height: 100px; */
  width: 100%;
  padding: 34px 75px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  transition: opacity .4s ease-in-out;
}
.hide {
  opacity: 1;
}

.gradient {
  display: none;
}

.right-container {
  display: flex;
  flex-direction: row;
}

.contact {
  text-decoration: underline;
  color: white;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 2.55px;
  cursor: pointer;
}

.logo {
  color: white;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2.55px;
  cursor: pointer;
}

.phrase {
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding-right: 40px;
  letter-spacing: 2.55px;
}
.hamburger {
  display: none;
}

.phrase {
  text-transform: uppercase;
}

@media screen and (max-width: 1024px) {
  .logo {
    font-size: 14px;
  }
  .phrase {
    display: none;
  }
  /* .contact {
    display: none;
  } */
  /* .hamburger {
    display: block;
  } */
  .navbar {
    padding: 24px 30px;
  }
  .gradient {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: initial;
    width: 100%;
    top: 0;
    position: absolute;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    background-color: black;
    z-index: -2;
    transform: translateX(-400px);
  }
  .gradient.show {
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
    z-index: 4;
    transform: translateX(-20px);
  }
}

@media screen and (max-width: 768px) { /* mobile < 768*/
  .logo {
    font-size: 12px;
  }
  .contact {
    font-size: 12px;
  }

}

.typography {
  display: block;
}

h1 {
  margin: 0px;
}
/* COLORS */

.typography-white {
  color: white;
}

.typography-black {
  color: black;
}

/* WEIGHTS */

.typography-light {
  font-weight: 300;
}

.typography-regular {
  font-weight: 400;
}

.typography-semibold {
  font-weight: 600;
}

.typography-bold {
  font-weight: 700;
}


/* alignments */

.typography-center {
  text-align: center;
}

.typography-right {
  text-align: right;
}

.typography-left {
  text-align: left;
}

/* VARIANTS */
.typography-heading {
  font-size: 25px;
}

.typography-number {
  font-size: 140px;
}

.typography-unit {
  font-size: 50px;
}

.typography-finePrint {
  font-size: 14px;
}

.typography-title {
  font-size: 70px;
  line-height: 1.06;
  letter-spacing: 3px;
  hanging-punctuation: first;
}

.typography-number-title {
  font-size: 120px;
  line-height: 1.2;
  letter-spacing: 3px;
  hanging-punctuation: first;
}

.typography-subtitle {
  font-size: 33px;
}
@media screen and (max-width: 1080px) { /* TABLET 768-1024*/
  .typography-title {
    font-size: 64px;
  }

}


@media screen and (max-width: 1024px) { /* TABLET 768-1024*/
  .typography-heading {
    font-size: 22px;
  }

  .typography-number {
    font-size: 100px;
  }

  .typography-unit {
    font-size: 36px;
  }

  .typography-title {
    font-size: 52px;
  }

  .typography-number-title {
    font-size: 100px;
  }

  .typography-subtitle {
    /* font-size: 18px; */
  }
}


@media screen and (max-width: 768px) { /* mobile < 768*/
  .typography-heading {
    font-size: 18px;
  }

  .typography-number {
    font-size: 80px;
    line-height: 1;
  }


  .typography-number-title {
    font-size: 80px;
  }

  .typography-unit {
    font-size: 28px;
  }

  .typography-title {
    font-size: 42px;
    line-height: 1.2;
    letter-spacing: 2px;
  }

  .typography-subtitle {
    font-size: 18px;
  }

}

@media screen and (max-width: 600px) { /* mobile < 768*/

  .typography-title {
    font-size: 38px;
  }

  br {
    display: none;
}

}
